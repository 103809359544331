/* Desktop View CSS */

@media (min-width: 1152px) {
    .loginPageLeftAndRightContent {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .loginLeftContent {
        width: 48%; 
        height: 100%; 
        padding: 22px;
    }

    .loginRightContent {
        width: 50%; 
        padding: 12px;        
    }
}

/* Smaller Screens View CSS */

@media (min-width: 800px) and (max-width: 1152px) {

    .loginPageLeftAndRightContent {
        display: flex;
        justify-content: center;
        width: 100%;
    }


    .loginLeftContent {
        display: none;
    }

    .loginRightContent {
        width: 50%; 
        padding: 12px;        
    }
}

/* Tablet View CSS */

@media (min-width: 768px) and (max-width: 800px) {
    .loginLeftContent {
        display: none;
    }
}

/* Mobile View CSS */

@media (min-width: 320px) and (max-width: 768px) {

    .loginLeftContent {
        display: none;
    }
}