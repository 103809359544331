/* Common css for all */

.removeFocusCss:focus {
    outline: none;
    box-shadow: none;
}

/* Desktop View CSS */

@media (min-width: 1200px) {

    .welcomeScreenResolutionCss {
        display: flex;
        justify-content: space-between;
    }

    .welcomeScreenLeftContentCss {
        width: 58%;
    }

    .welcomeScreenRightContentCss {
        width: 40%;
    }

    .offeringCardContentsCss {
        color: #fff;
        top: 50px;
        margin-left: 35%
    }

    .welcomeContentMargins {
        margin-bottom: 20px;
    }

    .welcomeClientCss {
        margin: 0px 0px 0px 10px;
        color: #7787BC;
        font-size: 40px;
    }

    .welcomeNoteCss {
        color: #170C6B;
        font-size: 40px;
    }

    .offeringImageCss {
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .offeringHeadingCss {
        font-size: 30px;
    }

    .offeringSubscribeForCss {
        font-size: 20px;
    }

    .downloadBrochureButtonContentCss:after {
        content: "Get Product Brochure";
    }

    .downloadBrochureButtonCss {
        margin: 3% 0px 0px 0px;
        width: 260px;
        height: 35px;
        color: #fff;
        border-radius: 15px;
        border: none;
        background-color: #3FABF3;
        box-shadow: 0px 0px 5px #fff;
    }

    .planCardCss {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 12px;
    }

    .planCardSubtitleCss {
        /* position: relative; */
        margin: 20px 0px 20px 0px;
        top: 10px;
        height: 50px;
        background-color: #EBF9EE;
    }

    .planPlaceholderCss {
        position: relative;
        top: 12px;
        left: 6px;
    }

    .planActiveStatusCss {
        float: right;
        height: 35px;
        position: relative;
        top: 7.5px;
        right: 6px;
        align-items: center;
        font-size: 14px;
        width: 90px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
    }

    .planMoreInfoCss {
        position: absolute;
        bottom: 0;
        padding: 2px;

        /* position: relative; */
        /* top: 50%; */
        display: flex;
        gap: 30px;
        /* justify-content: space-between; */
    }

    .subscriptionWarningCss {
        border: 1px dotted #c6a994;
        background-color: #ecf0ff;
        padding: 5px;
        margin-bottom: 10px
    }

    .subscriptionWarningTextCss {
        font-size: 14px;
    }
}

/* Smaller Screens View CSS */

@media (min-width: 768px) and (max-width: 1200px) {

    .welcomeClientCss {
        margin: 0px 0px 0px 10px;
        color: #7787BC;
        font-size: 40px;
    }

    .welcomeNoteCss {
        color: #170C6B;
        font-size: 40px;
    }


    .offeringCardContentsCss {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        color: #fff;
        justify-content: center;
        /* top: 50px;  */
        margin-left: 38%
    }

    .offeringImageCss {
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .offeringHeadingCss {
        font-size: 35px;
    }

    .offeringSubscribeForCss {
        font-size: 25px;
    }

    .downloadBrochureButtonContentCss:after {
        content: "Get Product Brochure";
    }

    .downloadBrochureButtonCss {
        width: 260px;
        height: 35px;
        color: #fff;
        border-radius: 15px;
        border: none;
        background-color: #3FABF3;
        box-shadow: 0px 0px 5px #fff;
    }

    .planCardCss {
        margin-top: 20px;
        width: 100%;
        height: 200px;
        position: relative;
        border-radius: 12px;
    }

    .planCardSubtitleCss {
        position: relative;
        top: 10px;
        height: 50px;
        background-color: #EBF9EE;
    }

    .planPlaceholderCss {
        position: relative;
        top: 12px;
        left: 6px;
    }

    .planActiveStatusCss {
        float: right;
        height: 35px;
        position: relative;
        top: 7.5px;
        right: 6px;
        align-items: center;
        font-size: 14px;
        width: 90px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
    }

    .planMoreInfoCss {
        position: absolute;
        bottom: 0;
        padding: 2px;

        /* position: relative; */
        /* top: 50%; */
        display: flex;
        gap: 30px;
        /* justify-content: space-between; */
    }

    
    .planMoreInfoCss {
        position: absolute;
        bottom: -12px;
        padding: 2px;

        /* position: relative; */
        /* top: 50%; */
        display: flex;
        gap: 30px;
        /* justify-content: space-between; */
    }

    .subscriptionWarningCss {
        border: 1px dotted #c6a994;
        background-color: #ecf0ff;
        padding: 5px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .subscriptionWarningTextCss {
        font-size: 14px;
    }

    .badgeMarginCss {
        margin: 10px 0px 0px 0px;
    }
}

/* Tablet View CSS */

@media (min-width: 500px) and (max-width: 768px) {

    .welcomeClientCss {
        margin: 0px 0px 0px 10px;
        color: #7787BC;
        font-size: 35px;
    }

    .welcomeNoteCss {
        color: #170C6B;
        font-size: 35px;
    }


    .offeringCardContentsCss {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        color: #fff;
        justify-content: center;
        /* top: 50px;  */
        margin-left: 33%
    }

    .offeringImageCss {
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .offeringHeadingCss {
        font-size: 25px;
    }

    .offeringSubscribeForCss {
        font-size: 18px;
    }

    .downloadBrochureButtonContentCss:after {
        content: "Get Product Brochure";
    }

    .downloadBrochureButtonCss {
        width: 230px;
        height: 30px;
        font-size: 14px;
        color: #fff;
        border-radius: 15px;
        border: none;
        background-color: #3FABF3;
        box-shadow: 0px 0px 5px #fff;
    }

    .planCardCss {
        margin-top: 20px;
        width: 100%;
        height: 200px;
        position: relative;
        border-radius: 12px;
    }

    .planCardSubtitleCss {
        position: relative;
        top: 10px;
        height: 50px;
        background-color: #EBF9EE;
    }

    .planPlaceholderCss {
        position: relative;
        top: 12px;
        left: 6px;
    }

    .planActiveStatusCss {
        float: right;
        height: 35px;
        position: relative;
        top: 7.5px;
        right: 6px;
        align-items: center;
        font-size: 14px;
        width: 90px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
    }

    .planMoreInfoCss {
        position: absolute;
        bottom: -12px;
        padding: 2px;

        /* position: relative; */
        /* top: 50%; */
        display: flex;
        gap: 30px;
        /* justify-content: space-between; */
    }

    .subscriptionWarningCss {
        border: 1px dotted #c6a994;
        background-color: #ecf0ff;
        padding: 3px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .subscriptionWarningTextCss {
        font-size: 13.5px;
    }

    .badgeMarginCss {
        margin: 10px 0px 0px 0px;
    }
}

/* Mobile View CSS */

@media (min-width: 320px) and (max-width: 500px) {

    .welcomeClientCss {
        margin: 0px 0px 0px 10px;
        color: #7787BC;
        font-size: 25px;
    }

    .welcomeNoteCss {
        color: #170C6B;
        font-size: 25px;
    }


    .offeringCardContentsCss {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        color: #fff;
        justify-content: center;
        /* top: 50px;  */
        margin: 3% 0 0 30%
    }

    .offeringImageCss {
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .offeringHeadingCss {
        font-size: 17px;
    }

    .offeringSubscribeForCss {
        margin-top: -5px;
        font-size: 13px;
    }

    .downloadBrochureButtonContentCss:after {
        content: "Get Brochure";
    }

    .downloadBrochureButtonCss {
        content: "your content";
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 22px;
        font-size: 13px;
        color: #fff;
        border-radius: 15px;
        border: none;
        background-color: #3FABF3;
        box-shadow: 0px 0px 5px #fff;
    }

    .startDateCss {
        font-size: 10px;
    }

    .endDateCss {
        font-size: 10px;
    }


    .planCardCss {
        margin-top: 20px;
        width: 100%;
        height: 220px;
        position: relative;
        border-radius: 12px;
    }

    .planCardSubtitleCss {
        position: relative;
        top: 10px;
        height: 50px;
        background-color: #EBF9EE;
    }

    .planPlaceholderCss {
        position: relative;
        top: 12px;
        left: 6px;
    }

    .planActiveStatusCss {
        float: right;
        height: 35px;
        position: relative;
        top: 7.5px;
        right: 6px;
        align-items: center;
        font-size: 14px;
        width: 90px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
    }

    .planMoreInfoCss {
        position: absolute;
        bottom: -16px;
        padding: 2px;

        /* position: relative; */
        /* top: 50%; */
        display: flex;
        gap: 30px;
        /* justify-content: space-between; */
    }

    .subscriptionWarningCss {
        border: 1px dotted #c6a994;
        background-color: #ecf0ff;
        padding: 3px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .subscriptionWarningTextCss {
        font-size: 12px;
    }

    .badgeMarginCss {
        margin: 10px 0px 0px 0px;
    }
}