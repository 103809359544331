.tableFixHead {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.tableFixHead tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 150px;
}
.tableFixHead thead tr {
  display: block;
}
.tableFixHead th,
.tableFixHead td {
  padding: 5px 10px;
  width: 200px;
}

.inputFields {
  display: flex;
  gap: 20px;
}
/* th {
    background: #ABDD93;
  }  */
@media (max-width: 768px) {
  .custom-container {
    flex-direction: column;
  }
  .custom-margin {
    margin: 2px !important;
  }
  .inputFields {
    flex-direction: column;
    gap: 0px;
  }
  .custom-Date-mobile {
    width: 50%;
  }
  .calander-mobile {
    margin-top: 8px;
  }
}
