/* Desktop View CSS */

@media (min-width: 1200px) {
  .planScreenResolutionCss {
    display: flex;
    justify-content: space-between;
  }

  .planScreenLeftContentCss {
    width: 58%;
  }

  .planScreenRightContentCss {
    width: 40%;
  }

  .planDetailsCardSubtitleCss {
    display: flex;
    justify-content: center;
  }

  .circularProgressBarDimensionsCss {
    width: 200px;
    height: 200px;
  }

  .planDetailsRightCardCss {
    border: none;
    border-radius: 20px;
    width: 100%;
  }

  .planDetailsRightCardBodyCss {
    text-align: center;
    background-color: #ecf0ff;
    border-radius: 20px;
    padding: 23px;
  }

  .totalReportsTitleCss {
    color: #6c757d;
  }

  .totalReportsNumberCss {
    font-size: 48px;
    color: #3567d6;
  }
}

/* Smaller Screens View CSS */

@media (min-width: 998px) and (max-width: 1200px) {
  .planScreenResolutionCss {
    display: flex;
    justify-content: space-between;
  }

  .planScreenLeftContentCss {
    width: 58%;
  }

  .planScreenRightContentCss {
    width: 40%;
  }

  .planDetailsCardSubtitleCss {
    display: flex;
    justify-content: center;
  }

  .circularProgressBarDimensionsCss {
    width: 150px;
    height: 150px;
  }

  .planDetailsRightCardCss {
    border: none;
    border-radius: 20px;
    width: 100%;
  }

  .planDetailsRightCardBodyCss {
    text-align: center;
    background-color: #ecf0ff;
    border-radius: 20px;
    padding: 10px;
  }

  .totalReportsTitleCss {
    color: #6c757d;
  }

  .totalReportsNumberCss {
    font-size: 48px;
    color: #3567d6;
  }
}

/* Tablet View CSS */

@media (min-width: 768px) and (max-width: 998px) {
  .planScreenResolutionCss {
    /* display: flex;
        justify-content: space-between; */
  }

  .planScreenLeftContentCss {
    /* width: 58%; */
  }

  .planScreenRightContentCss {
    margin-top: 20px;
    /* width: 40%; */
  }

  .planDetailsCardSubtitleCss {
    display: flex;
    justify-content: center;
  }

  .circularProgressBarDimensionsCss {
    width: 180px;
    height: 180px;
  }

  .planDetailsRightCardCss {
    border-radius: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .planDetailsRightCardBodyCss {
    text-align: center;
    background-color: #ecf0ff;
    border-radius: 20px;
    padding: 10px;
  }

  .totalReportsTitleCss {
    color: #6c757d;
  }

  .totalReportsNumberCss {
    font-size: 48px;
    color: #3567d6;
  }
}

/* Mobile View CSS */

@media (min-width: 320px) and (max-width: 768px) {
  .planScreenResolutionCss {
    /* display: flex;
        justify-content: space-between; */
  }

  .planScreenLeftContentCss {
    /* width: 58%; */
  }

  .planScreenRightContentCss {
    margin-top: 20px;
    /* width: 40%; */
  }

  .planDetailsCardSubtitleCss {
    display: flex;
    justify-content: center;
  }

  .circularProgressBarDimensionsCss {
    width: 180px;
    height: 180px;
  }

  .planDetailsRightCardCss {
    border-radius: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .planDetailsRightCardBodyCss {
    /* text-align: center;         */
    background-color: #ecf0ff;
    border-radius: 20px;
    padding: 10px;
  }

  .totalReportsTitleCss {
    color: #6c757d;
  }

  .totalReportsNumberCss {
    font-size: 48px;
    color: #3567d6;
  }
}

.spinner-border {
  border-width: 2px;
  color: #3567d6;
}
