/* Desktop View CSS */

@media (min-width: 1200px) {
  .columnBarFooterItemsCss {
    display: flex;
    justify-content: center;
  }

  .dayWiseFooterCss {
    text-decoration: 'none';
    color: inherit;
    margin-right: 20px;
  }

  .reportsGeneratedFooterCss {
    text-decoration: none;
    color: inherit;
  }

  .metricsChartButtonCss {
    background-color: #fff;
    border: 1.5px solid #3567d6;
    font-size: 16px;
    border-radius: 28px;
    height: 40px;
    margin: 10px 10px 0px 0px;
    width: 150px;
    color: black;
  }

  .metricsChartButtonCss:hover {
    background-color: #3567d6;
    border: 1.5px solid #3567d6;
    font-size: 16px;
    border-radius: 28px;
    height: 40px;
    width: 150px;
    color: white;
  }

  .metricsButtonsAlignCss {
    display: flex;
    justify-content: space-between;
  }

  .currentMonthTextCss:after {
    content: 'Current Month';
  }

  .previousMonthTextCss:after {
    content: 'Previous Month';
  }

  .customMonthTextCss:after {
    content: 'Custom Date';
  }

  .metricsSmallCardsCss {
    font-weight: unset;
    font-size: 14px;
    width: 140px;
  }

  .customShadowCss {
    box-shadow: rgb(63, 171, 243) 1px 1px 3px;
  }
}

/* Smaller Screens View CSS */

@media (min-width: 800px) and (max-width: 1200px) {
  .columnBarFooterItemsCss {
    display: flex;
    justify-content: center;
  }

  .dayWiseFooterCss {
    text-decoration: 'none';
    color: inherit;
    margin-right: 20px;
  }

  .reportsGeneratedFooterCss {
    text-decoration: none;
    color: inherit;
  }

  .metricsChartButtonCss {
    background-color: #fff;
    border: 1.5px solid #3567d6;
    font-size: 16px;
    border-radius: 28px;
    margin: 10px 10px 0px 0px;
    height: 40px;
    width: 150px;
    color: black;
  }

  .metricsChartButtonCss:hover {
    background-color: #3567d6;
    border: 1.5px solid #3567d6;
    font-size: 16px;
    border-radius: 28px;
    height: 40px;
    width: 150px;
    color: white;
  }

  .metricsButtonMarginsCss {
    margin-top: 10px;
  }

  .currentMonthTextCss:after {
    content: 'Current Month';
  }

  .previousMonthTextCss:after {
    content: 'Previous Month';
  }

  .customMonthTextCss:after {
    content: 'Custom Date';
  }

  .metricsSmallCardsCss {
    font-weight: unset;
    font-size: 14px;
    width: 140px;
  }

  .customShadowCss {
    box-shadow: rgb(63, 171, 243) 1px 1px 3px;
  }
}

/* Tablet View CSS */

@media (min-width: 768px) and (max-width: 800px) {
  .columnBarFooterItemsCss {
    display: flex;
    justify-content: center;
  }

  .dayWiseFooterCss {
    text-decoration: 'none';
    color: inherit;
    margin-right: 20px;
  }

  .reportsGeneratedFooterCss {
    text-decoration: none;
    color: inherit;
  }

  .metricsChartButtonCss {
    background-color: #fff;
    border: 1.5px solid #3567d6;
    font-size: 16px;
    border-radius: 28px;
    height: 40px;
    margin: 10px 10px 0px 0px;
    width: 150px;
    color: black;
  }

  .metricsChartButtonCss:hover {
    background-color: #3567d6;
    border: 1.5px solid #3567d6;
    font-size: 16px;
    border-radius: 28px;
    height: 40px;
    width: 150px;
    color: white;
  }

  .metricsButtonMarginsCss {
    margin-top: 10px;
  }

  .currentMonthTextCss:after {
    content: 'Current Month';
  }

  .previousMonthTextCss:after {
    content: 'Previous Month';
  }

  .customMonthTextCss:after {
    content: 'Custom Date';
  }

  .metricsSmallCardsCss {
    font-weight: unset;
    font-size: 14px;
    width: 140px;
  }

  .customShadowCss {
    box-shadow: rgb(63, 171, 243) 1px 1px 3px;
  }
}

/* Mobile View CSS */

@media (min-width: 320px) and (max-width: 768px) {
  .columnBarFooterItemsCss {
    display: flex;
    justify-content: center;
  }

  .dayWiseFooterCss {
    text-decoration: 'none';
    color: inherit;
    margin-right: 20px;
  }

  .reportsGeneratedFooterCss {
    text-decoration: none;
    color: inherit;
  }

  .metricsChartButtonCss {
    background-color: #fff;
    border: 1.5px solid #3567d6;
    font-size: 16px;
    border-radius: 28px;
    height: 35px;
    width: 100px;
    margin: 10px 10px 0px 0px;
    color: black;
  }

  .metricsChartButtonCss:hover {
    background-color: #3567d6;
    border: 1.5px solid #3567d6;
    font-size: 16px;
    border-radius: 28px;
    height: 35px;
    width: 100px;
    color: white;
  }

  .metricsButtonMarginsCss {
    /* border: 1px solid black; */
    margin: 10px;
  }

  .currentMonthTextCss:after {
    content: 'Current';
  }

  .previousMonthTextCss:after {
    content: 'Previous';
  }

  .customMonthTextCss:after {
    content: 'Custom';
  }

  .metricsSmallCardsCss {
    font-weight: unset;
    /* font-size: 14px; 
        width: 100px; */
    font-size: 16px;
  }

  .customShadowCss {
    box-shadow: rgb(63, 171, 243) 1px 1px 3px;
  }
}

/* CSS For all */

.customDateTag {
  width: 100%;
  border: 1px solid rgba(221, 224, 225, 1);
  border-radius: 5px;
  outline: none;
  margin-bottom: 15px;
  padding: 17px;
  height: 40px;
}

.labelTags {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}
.modalHeading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
}
