@media (min-width: 1200px) {

    
    .pieRightContentCssWidth {
        width: 80%;
    }

    .pieContentsResolutionCss {
        width: 100%;
        display: flex;
    }

    .pieLeftContentCss {
        width: 50%;
    }

    .pieRightContentCss {
        width: 50%;
    }

    .pieRightContentLineHeaderCss {
        color: #6A6B6B;
        font-size: 14px;
        font-weight: 550;
    }

    .pieRightContentLineCss {
        width: 80%;
        stroke-width: 2;
    }

    .top5packageButtonCss {
        background-color: #fff;
        border: 1.5px solid #3567D6;
        font-size: 16px;
        border-radius: 28px;
        margin: 10px 10px 0px 0px;
        height: 40px;
        width: 150px;
        color: black
    }

    .top5packageButtonCss:hover {
        background-color: #3567D6;
        border: 1.5px solid #3567D6;
        font-size: 16px;
        border-radius: 28px;
        height: 40px;
        width: 150px;
        color: white
    }

    .packageCurrentMonthTextCss:after {
        content: "Current Month"
    }

    .packagePreviousMonthTextCss:after {
        content: "Previous Month"
    }

    .packagePastMonthTextCss:after {
        content: "Past Month"
    }

    .topFivePackageButtonsCss {
        display: flex;
        justify-content: space-between;
    }
}

/* Smaller Screens View CSS */

@media (min-width: 800px) and (max-width: 1200px) {

    .pieContentsResolutionCss {
        width: 100%;
        /* display: flex; */
    }

    .pieLeftContentCss {
        width: 100%;
    }

    .pieRightContentCss {
        width: 100%;
    }

    .pieRightContentLineHeaderCss {
        color: #6A6B6B;
        font-size: 14px;
        font-weight: 550;
    }

    .pieRightContentLineCss {
        width: 100%;
        stroke-width: 2;
    }

    .top5packageButtonCss {
        background-color: #fff;
        border: 1.5px solid #3567D6;
        font-size: 16px;
        border-radius: 28px;
        margin: 10px 10px 0px 0px;
        height: 40px;
        width: 150px;
        color: black
    }

    .top5packageButtonCss:hover {
        background-color: #3567D6;
        border: 1.5px solid #3567D6;
        font-size: 16px;
        border-radius: 28px;
        height: 40px;
        width: 150px;
        color: white
    }

    .packageCurrentMonthTextCss:after {
        content: "Current Month"
    }

    .packagePreviousMonthTextCss:after {
        content: "Previous Month"
    }

    .packagePastMonthTextCss:after {
        content: "Past Month"
    }

    .topFivePackageButtonsCss {
        display: flex;
        justify-content: space-between;
    }

}

/* Tablet View CSS */

@media (min-width: 768px) and (max-width: 800px) {

    .pieRightContentCssWidth {
        width: 100%;
    }

    .pieRightContentLineHeaderCss {
        color: #6A6B6B;
        font-size: 14px;
        font-weight: 550;
    }

    .pieRightContentLineCss {
        width: 100%;
        stroke-width: 2;
    }

    .top5packageButtonCss {
        background-color: #fff;
        border: 1.5px solid #3567D6;
        font-size: 16px;
        margin: 10px 10px 0px 0px;
        border-radius: 28px;
        height: 40px;
        width: 150px;
        color: black
    }

    .top5packageButtonCss:hover {
        background-color: #3567D6;
        border: 1.5px solid #3567D6;
        font-size: 16px;
        border-radius: 28px;
        height: 40px;
        width: 150px;
        color: white
    }

    .packageCurrentMonthTextCss:after {
        content: "Current Month"
    }

    .packagePreviousMonthTextCss:after {
        content: "Previous Month"
    }

    .packagePastMonthTextCss:after {
        content: "Past Month"
    }
}

/* Mobile View CSS */

@media (min-width: 320px) and (max-width: 768px) {
    .pieRightContentCssWidth {
        width: 100%;
    }

    .pieRightContentLineHeaderCss {
        color: #6A6B6B;
        font-size: 12px;
        font-weight: 550;
    }

    .pieRightContentLineCss {
        width: 100%;
        stroke-width: 2;
    }

    .top5packageButtonCss {
        background-color: #fff;
        border: 1.5px solid #3567D6;
        font-size: 16px;
        border-radius: 28px;
        margin: 10px 10px 0px 0px;
        height: 35px;
        width: 100px;
        color: black
    }

    .top5packageButtonCss:hover {
        background-color: #3567D6;
        border: 1.5px solid #3567D6;
        font-size: 16px;
        border-radius: 28px;
        height: 35px;
        width: 100px;
        color: white
    }

    .packageCurrentMonthTextCss:after {
        content: "Current"
    }

    .packagePreviousMonthTextCss:after {
        content: "Previous"
    }

    .packagePastMonthTextCss:after {
        content: "Past"
    }
}