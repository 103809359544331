/* Desktop View CSS */

.dropdownCss {
  padding: 10px;
  border-radius: 20px;
}

@media (min-width: 1200px) {
  .navLogoCss {
    width: 180px;
    filter: brightness(1.1);
    height: 65px;
    justify-content: center;
    position: relative;
    display: flex;
  }

  .navbarMainCss {
    padding: 10px;
    background-color: #fff;
    height: 75px;
  }

  .dropdownCss:focus {
    outline: none;
    box-shadow: none;
  }
}

/* Smaller Screens View CSS */

@media (min-width: 998px) and (max-width: 1200px) {
  .navLogoCss {
    width: 180px;
    filter: brightness(1.1);
    height: 65px;
    justify-content: center;
    position: relative;
    display: flex;
  }

  .navbarMainCss {
    padding: 10px;
    background-color: #fff;
    height: 75px;
  }
}

/* Tablet View CSS */

@media (min-width: 768px) and (max-width: 998px) {
  .navLogoCss {
    width: 180px;
    filter: brightness(1.1);
    height: 40px;
    justify-content: center;
    position: relative;
    display: flex;
  }

  .navbarMainCss {
    background-color: #fff;
    height: 75px;
  }
}

/* Mobile View CSS */

@media (min-width: 500px) and (max-width: 768px) {
  .navLogoCss {
    width: 180px;
    filter: brightness(1.1);
    height: 40px;
    justify-content: center;
    position: relative;
    display: flex;
  }

  .navbarMainCss {
    background-color: #fff;
    height: 75px;
  }
}

/* Very Small Screens */

@media (min-width: 300px) and (max-width: 500px) {
  .navLogoCss {
    position: relative;
    width: 180px;
    filter: brightness(1.1);
    height: 40px;
    justify-content: center;
    position: relative;
    display: flex;
  }

  .navbarMainCss {
    background-color: #fff;
    height: 75px;
  }
  .dropdownCss {
    padding: 8px;
    font-size: 14px;
    text-align: center;
    border-radius: 8px;
  }
}
